import { XF } from "./XF";

export function desktopAdhesionClose(isAdSlotHidden) {
  const desktopAdhesionDismissed = "Desktop_Adhesion_Dismissed";
  const footerAdContainer = document.querySelector(
    ".desktop-adhesion-ad__footer-ad-container"
  );
  if (isAdSlotHidden || XF.Cookie.get(desktopAdhesionDismissed)) {
    if (footerAdContainer) {
      footerAdContainer.remove();
    }
    return;
  }
  desktopAdhesionCloseInternal(desktopAdhesionDismissed, footerAdContainer);
}

document.addEventListener("readystatechange", () => {
  if (document.readyState === "complete") {
    desktopAdhesionClose(false);
  }
});

function desktopAdhesionCloseInternal(
  desktopAdhesionDismissed,
  footerAdContainer
) {
  const titleCloser = document.querySelector(
    "#desktop-adhesion-ad__container .overlay-titleCloser"
  );
  if (titleCloser == null) {
    return;
  }
  titleCloser.addEventListener("click", (e) => {
    e.preventDefault();
    if (
      window.adConfig &&
      window.adConfig.desktopAdhesionCookieDuration != null
    ) {
      const date = new Date();
      date.setMinutes(
        date.getMinutes() + window.adConfig.desktopAdhesionCookieDuration
      );
      XF.Cookie.set(desktopAdhesionDismissed, true, date);
    }
    let desktopAdhesionAdUnit;
    googletag.cmd.push(() => {
      googletag
        .pubads()
        .getSlots()
        .forEach((i) => {
          if (i.getSlotElementId() === "Desktop_Adhesion") {
            desktopAdhesionAdUnit = i;
          }
        });
      if (window.deployads) {
        deployads.push(() => {
          deployads.gpt.destroySlots([desktopAdhesionAdUnit]);
        });
      } else {
        googletag.cmd.push(() => {
          googletag.destroySlots([desktopAdhesionAdUnit]);
        });
      }
    });
    if (footerAdContainer) {
      footerAdContainer.remove();
    }
  });
}
